import { render, staticRenderFns } from "./height.vue?vue&type=template&id=55b6bfd4&scoped=true&"
import script from "./height.vue?vue&type=script&lang=js&"
export * from "./height.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b6bfd4",
  null
  
)

export default component.exports
<template>
  <div>
    <CCollapse :show="!collapseHeightPlan">
      <CRow class="mt-2">
        <CCol sm="12">
          <CRow class="mb-3">
            <CCol sm="8" class="d-flex align-items-center ">
              <CButton
                  color="edit"
                  class="mr-2"
                  v-c-tooltip="{
                    content: $t('label.return'),
                    placement: 'top',
                  }"
                  size="sm"
                  @click="closeCollapse"
                >
                  <CIcon name="cil-chevron-left-alt" />
                </CButton>
                <h6 class="mt-2"> {{ $t('label.block')+': '+titleHeight}}</h6>
            </CCol>  
          </CRow>
          <CTabs :active-tab="Tab" @update:activeTab="handleTab">
            <CTab :title="$t('label.height_')+'S'">
              <HeightTab :Tab="Tab" class="ml-1 mr-1"/>
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </CCollapse>
    <CCollapse :show="collapseHeightPlan">
        <div v-show ="collapseHeightPlan">
          <HeightPlan/>
        </div>
    </CCollapse>
  </div>
</template>
  
  <script>
  import { mapState } from "vuex";
  import CustomTabs from '@/components/tabs/CustomTabs';
  import CustomTab from '@/components/tabs/CustomTab';
  import HeightTab from './height';
  import HeightPlan from './bay-plan-view/index';
  
  //Funcion data() del ciclo.
  function data() {
    return {
      isSubmit: false,
      isLoadingGeneral: false,
      Tab: 0,
    }
  }
  
  //Methods:
function handleTab(Tab) {
  this.Tab = Tab;
}
function isActiveTab(current) {
  return this.Tab == current;
}
function closeCollapse(){
    this.$store.state.yard.collapseBlocks = false;
}
export default {
  name: 'cargo-type',
  components: {
      CustomTabs,
      CustomTab,
      HeightTab,
      HeightPlan
  },
  props: {active: Number, default: 0, titleHeight: String},
  data,
  methods: {
      handleTab,
      isActiveTab,
      closeCollapse
  },
  computed: {
      ...mapState({
      activeTab: state => state.yard.tabIndex,
      collapseHeightPlan: state => state.yard.collapseHeightPlan,
      }),
  },
  watch: {
      activeTab: function (newTab) {
      if (newTab == 1) {
          this.Tab = 0;
      }
      }
  }
}
</script>
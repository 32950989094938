<template>
   <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mt-4">
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="formatedItems"
          :fields="fields"
          :noItemsView="tableText.noItemsViewText"
          column-filter
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          hover
          small
          sorter
          pagination
          :items-per-page="5"
        >
        <template #Status="{ item }">
          <td class="text-center align-middle">
            <CBadge :color="getBadge(item.Status)">
              {{$t('label.'+item.Status)}}
            </CBadge>
          </td>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center">
            <CButton
              color="plan"
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.heightPlan'),
                placement: 'top-end'
              }"
            @click="getHeightPlan(item)"
            >
            <CIcon name="heightPlan" />
          </CButton>
          </td>
        </template>
        </dataTableExtended>
      </CCol>
    </CRow>
  </div> 
</template>
  
  <script>
  import { DateFormater } from "@/_helpers/funciones";
  import General from "@/_mixins/general";
  import { mapState } from "vuex";
  import HeightPlan from './bay-plan-view/index';
  
  //DATA
  function data() {
    return {
      items: [],
      Loading:  false,
      modal: false,
      edit: false,
      titleModal: ''
    };
  }
 
  //METHOD
  function getHeightPlan(item) {
    this.getBlockConfig(item)
   
  }

  function getBlockConfig(item) {
    this.Loading = true;
    this.$http.ejecutar('GET', 'YardBlockList-by-YardBlockId', { YardId: this.yardSelected.YardId, YardBlockId: this.YardBlockId })
    .then(response => {
      if(response.data.data){
        let block = response.data.data[0];
        let YardHeight = block.HeightJson.filter((element) => element.YardHeightId===item.YardHeightId);
        if(YardHeight.length>0){

          YardHeight[0].StackJson.map((item, position) =>{
              item.SlotJson.map((item, positionJson) =>{
                YardHeight[0].StackJson[position].SlotJson.splice(positionJson, 1, {
                  ...item,
                  YardSlotStatusName: item.YardSlotStatusNameEn
                });
              })
          })
          
          YardHeight.length>0 && this.$store.commit("yard/changePositions", { item: YardHeight[0].StackJson });
          this.$store.state.yard.YardHeight = YardHeight[0].HeightCode;
          this.$store.state.yard.YardBlock = block.BlockAlias;
          this.$store.state.yard.YardHeightId = YardHeight[0].YardHeightId;
          this.$store.state.yard.collapseHeightPlan = true;
          this.$store.state.yard.fgOperative = block.FgOperative ?? false;
        }
      }
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.Loading = false;
    });
  }
  
  // COMPUTED
  function fields() {
    return [
      { key: "Nro", label: "#", _style: "width:1%;",filter: false,},
      { key: "HeightCode", label: this.$t("label.height_"),_styles: "width: 20%;",_classes:'text-center'},
      { key: "Teus", label: 'TEUS',_styles: "width: 20%;",_classes:'text-center'},
      { key: "TransaLogin", label: this.$t("label.user"),_styles: "width: 10%;",_classes:'text-center'},
      { key: "FormatedDate", label: this.$t("label.date"),_styles: "width: 10%;",_classes:'text-center'},
      { key: "Status", label: this.$t("label.status"),_styles: "width: 10%;",_classes:'text-center'},
      { key: "Detalle", label: "", sorter: false, filter: false,_styles: "min-width:45px;",},
    ];
  }

  function formatedItems() {
    return this.heightJson.map((items) =>
      Object.assign({}, items, {  
        Nro: items.Nro ?? 'N/A',
        Usuario: items.Usuario ?? "N/A",
        Status: items.Status ?? "N/A",
        FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
        _classes: items.Status === "INACTIVO" ? "table-danger" : "",
      })
    );
  }
  function getBranchId() {
    return this.branch ? this.branch.CompanyBranchId : "";
  }

  export default {
    name: "historic",
    components: {
      HeightPlan,
    },
    mixins: [General],
    props:{Tab: Number},
    data,
    methods: {
      getHeightPlan,
      getBlockConfig,
    },
    computed: {
      fields,
      formatedItems,
      getBranchId,
      ...mapState({
        branch: (state) => state.auth.branch,
        heightJson: state => state.yard.heightJson,
        collapseHeightPlan: state => state.yard.collapseHeightPlan,
        yardSelected: state => state.yard.yardSelected,
        YardBlockId: state => state.yard.YardBlockId,
      }),
    },
    watch: {
      Tab:function (val){
        if(val == 0){
        }
      }
    }
  };
  </script>
  <style scoped>
  </style>